import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import nl from './nl';
import en from './en';

interface Page {
  title: string;
  components: any;
}

export interface IText extends LocalizedStringsMethods {
  loading: string;
  basic: {
    title: string;
    settings: string;
    dateTimeFormat: string;
    dateFormat: string;
    error: string;
    back: string;
    subscription: string;
    logout: string;
    backToDashboard: string;
    delete: string;
  };
  pages: {
    dashboard: Page;
    settings: Page;
    admin: Page;
  };
  modals: {
    importExcel: {
      mainText: string;
    };
    importXml: {
      mainText: string;
      fileError: string;
    };
    addCredit: {
      openButton: string;
      title: string;
      addCredit: string;
      cancel: string;
      modalText: string;
      value: string;
      vatText: string;
      checkStatus: string;
      success: string;
      successClose: string;
      errors: {
        valueFormat: string;
        valueToLow: string;
        unknown: string;
        paymentFailed: string;
      };
    };
    startSubscription: {
      openButton: string;
      title: string;
      success: string;
      successClose: string;
      modalText: string;
      cancel: string;
      buySubscription: string;
    };
    cancelSubscriptionModal: {
      openButton: string;
      title: string;
      success: string;
      successClose: string;
      modalText: string;
      cancel: string;
      cancelSubscription: string;
    };
    creditAction: {
      title: string;
      subTextPart1: string;
      subTextPart2: string;
      agree: string;
      disagree: string;
    };
  };
  forms: {
    changeAutomatedLogout: {
      saved: string;
      error: string;
    };
    loginForm: {
      title: string;
      login: string;
      remeberMe: string;
      error: string;
      goRegister: string;
      goForgotPassword: string;
      emailConfirmed: string;
      confirmEmailError: string;
    };
    twoFactorAuthForm: {
      title: string;
      sentCode: string;
      didntReceiveCode: string;
      verifyBtn: string;
      sendAgain: string;
      code: string;
      error: string;
    };
    inviteFriendForm: {
      title: string;
      inviteText: string;
      sendCode: string;
      sendMail: string;
      opened: string;
      createdAccount: string;
      sent: string;
      copyCode: string;
    };
    changeSubscriptionForm: {
      changeSubscriptionText: string;
      startDate: string;
      nextPaymentDate: string;
      cancelSubscription: string;
    };
    changePasswordForm: {
      savePassword: string;
      passwordError: string;
      passwordsNotEqual: string;
      success: string;
      title: string;
      error: string;
      defineNewPwdText: string;
      automaticLogout: string;
      automaticLogoutSelectText: string;
      SelectAutomaticLogoutOption: string;
    };
    sepaForm: {
      error: string;
      notComplete: string;
      addTransaction: string;
      total: string;
      totalTransactions: string;
      insufficientFunds: string;
    };
    validation: {
      required: string;
      ibanFormat: string;
    };
    registerForm: {
      goLogin: string;
      title: string;
      success: string;
      error: string;
      passwordFormatError: string;
      passwordShortError: string;
      passwordsDontMatchError: string;
    };
    forgotPasswordForm: {
      error: string;
      title: string;
      sendMail: string;
      success: string;
    };
    changeAccount: {
      error: string;
      success: string;
      save: string;
      title: string;
      subtitle: string;
      phoneNumber: string;
      city: string;
      houseNumber: string;
      deleteAccount: string;
    };
  };
  fields: {
    email: string;
    firstName: string;
    lastName: string;
    lastNamePrefix: string;
    company: string;
    password: string;
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
    date: string;
    actions: string;
    selectEmpty: string;
    address: string;
    postalCode: string;
    country: string;
    sepa: {
      credit: string;
      debit: string;
      method: string;
      methodChoice: {
        normal: string;
        business: string;
      };
      frequency: string;
      frequencyChoice: {
        once: string;
        continuous: string;
      };
      paymentType: string;
      paymentTypeChoice: {
        salary: string;
        nonSalary: string;
      };
      collectionDate: string;
      companyName: string;
      iban: string;
      bic: string;
      creditorId: string;
      batch: string;
      batchChoice: {
        asBatch: string;
        nonBatch: string;
      };
      payments: string;
      invalidTransactions: string;
      paymentInfo: {
        mandateId: string;
        mandateDate: string;
        amount: string;
        name: string;
        description: string;
        remove: string;
      };
      endToEndId: string;
    };
  };
  errors: {
    importFailed: string;
  };
}

const text: IText = new LocalizedStrings({
  en,
  nl
});

export default text;
