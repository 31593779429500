import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import Moment from 'react-moment';
import {
  deleteDownload,
  downloadXml,
  loadSepaFromDownload
} from '../../reducers/dashboardActions';
import text from '../../language/text';
import CreditActionModal from '../modals/basicModals/CreditActionModal';
import DownloadsListArrow from '../../assets/svgIcons/DownloadsListArrow';
import styles from '../../assets/cssModules/dashboardPage.module.css';

interface IProps {
  dispatch: any;
  download: any;
  role: any;
}

function RecentDownloadRow(props: IProps) {
  const { dispatch, download, role } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [creditActionOpen, setCreditActionOpen] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadXml = () => {
    dispatch(downloadXml(download.id));
    handleClose();
  };

  const openCreditActionModal = () => {
    if (role === 'FREE') {
      setCreditActionOpen(true);
    } else {
      dispatch(downloadXml(download.id));
    }
  };

  const handleSetForm = () => {
    dispatch(loadSepaFromDownload(download.id));
    handleClose();
  };

  const handleDeleteDownload = () => {
    dispatch(deleteDownload(download.id));
    handleClose();
  };

  return (
    <Box style={{ marginTop: '7%' }}>
      <tr>
        <td className={styles.DownloadsRow}>
          <Moment format="HH:mm DD/MM/YYYY" date={download.date} />
        </td>
        <td className="text-center">
          <Box>
            <IconButton
              style={{ marginLeft: '160%' }}
              color="primary"
              size="medium"
              onClick={handleClick}>
              <DownloadsListArrow />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem id={download.id} onClick={handleSetForm}>
                {
                  text.pages.dashboard.components.recentDownloadsButtons
                    .editInForm
                }
              </MenuItem>
              <MenuItem id={download.id} onClick={openCreditActionModal}>
                {
                  text.pages.dashboard.components.recentDownloadsButtons
                    .downloadXml
                }
              </MenuItem>
              <MenuItem id={download.id} onClick={handleDeleteDownload}>
                {text.pages.dashboard.components.recentDownloadsButtons.delete}
              </MenuItem>
            </Menu>
          </Box>
          <CreditActionModal
            open={creditActionOpen}
            handleCancel={() => setCreditActionOpen(false)}
            handleClose={handleDownloadXml}
          />
        </td>
      </tr>
    </Box>
  );
}

function mapStateToProps(state: any) {
  const { auth } = state;
  const { role } = auth.user || {
    role: 'UNKNOWN'
  };
  return {
    role
  };
}

export default connect(mapStateToProps)(RecentDownloadRow);
