import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import text from '../../language/text';
import { Field } from 'redux-form';
import { renderTextField } from '../utils/materialUI';

const SepaFormDebitButtons = ({
  isDebit,
  handleIsDebitChange
}: {
  isDebit: boolean;
  handleIsDebitChange: (change: boolean) => void;
}) => {
  return (
    <div>
      <ButtonGroup className="mb-2">
        <Button
          variant="contained"
          color={isDebit ? 'primary' : 'default'}
          onClick={() => handleIsDebitChange(true)}>
          {text.fields.sepa.debit}
        </Button>
        <Button
          variant="contained"
          color={isDebit ? 'default' : 'primary'}
          onClick={() => handleIsDebitChange(false)}>
          {text.fields.sepa.credit}
        </Button>
      </ButtonGroup>
      <Field
        name={'debit'}
        type={'text'}
        component={renderTextField}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default SepaFormDebitButtons;
