import text from '../language/text';
import { apiDownload, apiPost, apiPostFile, downloadFile } from '../utils/api';
import { fetchDownloads } from './dashboardActions';
import { clearModal } from './templateActions';
import { reset } from 'redux-form';
import { loadCurrentUser } from './authActions';
import moment from 'moment';

export const REQUEST_SEPA = 'REQUEST_SEPA';
export const RECEIVE_SEPA = 'RECEIVE_SEPA';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const CHANGE_TRANSACTION = 'CHANGE_TRANSACTION';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION';
export const SEPA_ERROR = 'SEPA_ERROR';
export const SEPA_UPLOAD_ERROR = 'SEPA_UPLOAD_ERROR';
export const CLEAR_SEPA = 'CLEAR_SEPA';
export const REQUEST_EXCEL = 'REQUEST_EXCEL';
export const RECEIVE_EXCEL = 'RECEIVE_EXCEL';
export const SET_SEPA = 'SET_SEPA';
export const REQUEST_UPLOAD_EXCEL = 'REQUEST_UPLOAD_EXCEL';
export const RECEIVE_UPLOAD_EXCEL = 'RECEIVE_UPLOAD_EXCEL';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const RECEIVE_COLUMNS = 'RECEIVE_COLUMNS';
export const SWITCH_FORM = 'SWITCH_FORM';

export function requestSepa() {
  return {
    type: REQUEST_SEPA
  };
}

export function receiveSepa() {
  return {
    type: RECEIVE_SEPA
  };
}

export function requestExcel() {
  return {
    type: REQUEST_EXCEL
  };
}

export function receiveExcel() {
  return {
    type: RECEIVE_EXCEL
  };
}

export function addTransaction(transaction: any = {}) {
  return {
    type: ADD_TRANSACTION,
    transaction
  };
}

export function removeTransaction(index: number) {
  return {
    type: REMOVE_TRANSACTION,
    index
  };
}

export function sepaError(error: string) {
  return {
    type: SEPA_ERROR,
    error
  };
}

export function sepeUploadError(error: string) {
  return {
    type: SEPA_UPLOAD_ERROR,
    error: error,
    isFetching: false
  };
}

export function transactionChange(index: number, transaction: any) {
  return {
    type: CHANGE_TRANSACTION,
    index,
    transaction
  };
}

export function clearSepa() {
  return {
    type: CLEAR_SEPA
  };
}

export function setSepa(sepa: any) {
  return {
    type: SET_SEPA,
    sepa
  };
}

export function setTransactions(sepa: any) {
  return {
    type: SET_TRANSACTIONS,
    sepa
  };
}

export function requestUploadExcel() {
  return {
    type: REQUEST_UPLOAD_EXCEL
  };
}

export function receiveUploadExcel() {
  return {
    type: RECEIVE_UPLOAD_EXCEL
  };
}

export function receiveColumns(columns: any[], file: any) {
  return {
    type: RECEIVE_COLUMNS,
    columns,
    file
  };
}

export function switchInForm(showForm: boolean) {
  return {
    type: SWITCH_FORM,
    showForm: showForm
  };
}

export function createSepa(basicInfo: any, receiver: any, transactions: any) {
  return (dispatch: any, getState: any) => {
    dispatch(requestSepa());

    const url = basicInfo.isDebit ? '/sepa/debit' : '/sepa/credit';
    const fileName = basicInfo.isDebit
      ? 'YourSepa-DD-' + moment().format('YYYYMMDD-HHmmss') + '-MT.xml'
      : 'YourSepa-CT-' + moment().format('YYYYMMDD-HHmmss') + '-MT.xml';

    return apiPost(
      url,
      { basicInfo, receiver, transactions },
      getState().auth.token
    )
      .then((r: any) => r.blob())
      .then((response: any) => {
        downloadFile(response, fileName);
        dispatch(receiveSepa());
        dispatch(fetchDownloads());
        dispatch(reset('SepaForm'));
        dispatch(loadCurrentUser(getState().auth.token));
      })
      .catch((reason: any) => {
        if (reason.message && reason.message === 'InsufficientFunds') {
          dispatch(sepaError(text.forms.sepaForm.insufficientFunds));
        } else {
          dispatch(sepaError(text.forms.sepaForm.notComplete));
        }
      });
  };
}

export function fetchExcelColumns(file: any) {
  return (dispatch: any, getState: any) => {
    dispatch(requestUploadExcel());

    return apiPostFile('/sepa/excel/mapping', file, getState().auth.token)
      .then((r: any) => r.json())
      .catch((reason: any) => {
        dispatch(sepeUploadError(reason));
      })
      .then((response: any) => {
        console.log(response);
        dispatch(receiveColumns(response, file));
      })
      .catch((reason: any) => {
        dispatch(sepeUploadError(reason));
      });
  };
}

export function uploadExcel(file: any, mapping: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch(requestUploadExcel());

    return apiPostFile(
      `/sepa/excel?${createQueryParams(mapping)}`,
      file,
      getState().auth.token
    )
      .then((r: any) => r.json())
      .then((response: any) => {
        dispatch(reset('SepaForm'));
        dispatch(receiveUploadExcel());
        dispatch(setTransactions(response));
        dispatch(switchInForm(true));
      })
      .catch((reason: any) => {
        dispatch(sepeUploadError(reason));
      });
  };
}

export function downloadExcel(transactions: any) {
  return (dispatch: any, getState: any) => {
    dispatch(requestExcel());

    return apiDownload(
      '/sepa/csv',
      'sepa_template.xlsx',
      transactions,
      getState().auth.token
    )
      .then(() => {
        dispatch(receiveExcel());
      })
      .catch((reason: any) => {
        console.error(reason);
        dispatch(sepaError(text.forms.sepaForm.error));
      });
  };
}

function createQueryParams(params: any) {
  console.log('params', params);
  return Object.keys(params)
    .filter((key: string) => params[key] !== null)
    .map((key) => key + '=' + params[key])
    .join('&');
}
