const nl = {
  loading: 'Even geduld aub...',
  basic: {
    title: 'YourSepa',
    settings: 'Instellingen',
    dateTimeFormat: 'HH:mm DD/MM/YYYY',
    dateFormat: 'DD/MM/YYYY',
    error: 'Er ging iets mis...',
    back: 'Terug',
    subscription: 'Lidmaatschap',
    logout: 'Log uit',
    backToDashboard: 'Ga terug naar het dashboard',
    delete: 'Verwijder'
  },
  pages: {
    dashboard: {
      title: 'Dashboard',
      components: {
        recentDownloads: 'Recente Downloads',
        recentDownloadsNotFound: 'Geen downloads gevonden',
        recentDownloadsButtons: {
          downloadXml: 'Download XML',
          editInForm: 'Bewerk',
          delete: 'Verwijder'
        },
        makeSepa: 'Maak nieuwe SEPA',
        makeSepaButton: 'Maak SEPA',
        downloadExcel: 'Download Excel',
        clearForm: 'Verwijder alles',
        templates: 'Maak SEPA',
        templatesSubtext: 'Upload bestand of start een nieuw document',
        or: 'of',
        templateButtons: {
          existingXml: 'Importeer SEPA XML',
          importCsv: 'Importeer Excel bestand',
          downloadTemplate: 'Download excel sjabloon',
          startNewDocument: 'Start nieuw document',
          credit: 'Excasso (Betalingen doen naar andere)',
          debit: 'Incasso (Betalingen ontvangen)'
        }
      }
    },
    settings: {
      title: 'Instellingen',
      components: {
        startSubscription: {
          title: 'Upgrade Account',
          upgradeText:
            'Upgrade uw account naar een jaarlijks abbonement om oneindig veel SEPA documenten te maken!',
          button: 'Upgrade',
          amountSubText: 'Per jaar (excl. btw)',
          cancelSubscription: 'Cancel abonnement',
          subscription: 'Abonnement',
          subscriptionStart: 'Startdatum abonnement',
          nextPayment: 'Volgende automatische betaling'
        },
        changePasswordForm: {
          title: 'Wijzig wachtwoord'
        },
        accountData: {
          title: 'Wijzig account'
        },
        sepaData: {
          title: 'Standaard waarden SEPA'
        }
      }
    },
    admin: {
      title: 'Admin features',
      components: {
        userDetails: {
          title: 'Details gebruiker',
          error: 'Gebruiker is niet gevonden',
          userPayments: {
            error: 'Geen betalingen gevonden'
          }
        }
      }
    }
  },
  modals: {
    importExcel: {
      mainText:
        'Upload het ingevulde excel sjabloon. Als het hele sjabloon klopt worden de transacties in het formulier geladen en kunt u ze verder bewerken of downloaden als SEPA XML.'
    },
    importXml: {
      mainText:
        'Upload een bestaand SEPA bestand om deze verder aan te passen met YourSepa!',
      fileError: 'Geen geldig bestand geselecteerd'
    },
    addCredit: {
      openButton: 'Credits toevoegen',
      title: 'Credits toevoegen',
      addCredit: 'Betaal',
      cancel: 'Annuleer',
      modalText:
        'Voeg credits toe om SEPA documenten te kunnen maken. U kunt zoveel toevoegen als u wilt. Let wel op, ongebruikte credits kunnen niet worden teruggestort.',
      value: 'Bedrag',
      vatText: '21% BTW wordt toegevoegd bij het afrekenen',
      success: 'U heeft betaald!',
      successClose: 'Sluiten',
      checkStatus: 'Betaling wordt gestart',
      errors: {
        valueFormat: 'Vul een getal in',
        valueToLow: 'Het minimum oplaad bedrag is %s',
        unknown: 'Er ging iets mis!',
        paymentFailed: 'Betaling mislukt, probeer het later nog eens'
      }
    },
    startSubscription: {
      openButton: 'Upgrade',
      title: 'Upgrade Account',
      success:
        'Betaling gelukt! U kunt nu zoveel SEPA documenten maken als u maar wilt!',
      successClose: 'Sluiten',
      modalText:
        'Voor maar % (excl. btw) per jaar kunt u zo veel SEPA documenten maken als u maar wilt!',
      cancel: 'Annuleren',
      buySubscription: 'Koop Abbonement'
    },
    cancelSubscriptionModal: {
      openButton: 'Annuleer abonnement',
      title: 'Abonnement opzeggen',
      success:
        'Abonnement is opgezegd. U kan niet meer oneindig veel SEPA documenten maken.',
      successClose: 'Sluiten',
      modalText:
        'Weet u het zeker? Als u nu opzegt kunt u YourSepa niet meer oneindig gebruiken.',
      cancel: 'Sluiten',
      cancelSubscription: 'Ja, annuleer mijn abonnement'
    },
    creditAction: {
      title: 'Let op: Deze actie kost geld',
      subTextPart1: 'Het downloaden van de XML kost u €',
      subTextPart2:
        '. Wilt u onbeperkt downloaden sluit dan een abbonement af! ',
      agree: 'Ja, download XML',
      disagree: 'Annuleer'
    }
  },
  forms: {
    loginForm: {
      title: 'Login',
      login: 'Login',
      remeberMe: 'Onthoudt mij',
      error: 'Gebruikersnaam en wachtwoord combinatie klopt niet',
      goRegister: 'Nog geen account? Registreer nu!',
      goForgotPassword: 'Wacthwoord vergeten',
      emailConfirmed: 'E-mail bevestiged',
      confirmEmailError: 'Er ging iets mis'
    },
    changeSubscriptionForm: {
      changeSubscriptionText: 'Wijzig uw lidmaatschap',
      startDate: 'Startdatum lidmaatschap:',
      nextPaymentDate: 'Volgende automatische afschrijving:',
      cancelSubscription: 'Wijzig lidmaatschap'
    },
    twoFactorAuthForm: {
      title: 'Twee-staps verificatie',
      sentCode: 'We hebben een code naar jouw e-mailadres verzonden',
      didntReceiveCode: 'Heb je geen code ontvangen?',
      sendAgain: 'Verzend code opnieuw',
      verifyBtn: 'Verifieer',
      code: 'code',
      error: 'code is onjuist'
    },
    inviteFriendForm: {
      title: 'Nodig iemand uit',
      inviteText: 'Nodig je vrienden uit voor YourSepa en verdien',
      sendCode: 'Verstuur code',
      sendMail: 'Verstuur email',
      opened: 'Geopend',
      createdAccount: 'Account aangemaakt',
      sent: 'Verstuurd',
      copyCode: 'Kopieer'
    },
    changePasswordForm: {
      savePassword: 'Opslaan',
      passwordError: 'Wachtwoord onjuist',
      passwordsNotEqual: 'Nieuwe wachtwoorden komen niet overeen',
      success: 'Nieuwe wachtwoord is opgeslagen!',
      title: 'Wijzig wachtwoord',
      error: 'Deze link is niet meer geldig',
      defineNewPwdText: 'Definieer nieuw wachtwooed voor uw account',
      automaticLogout: 'Automatisch uitloggen',
      automaticLogoutSelectText:
        'Selecteer hieronder wanneer u uitgelogd wilt zijn',
      SelectAutomaticLogoutOption: 'Na 5 minuten'
    },
    sepaForm: {
      error: 'Er ging iets mis!',
      notComplete: 'Niet alle velden zijn ingevuld',
      addTransaction: 'Betaling toevoegen',
      total: 'Totaal',
      totalTransactions: 'Transacties',
      insufficientFunds: 'Niet genoeg credits!'
    },
    validation: {
      required: 'Verplicht',
      ibanFormat: 'Iban niet het juiste format'
    },
    registerForm: {
      title: 'Registreer',
      goLogin: 'Al een account? Login!',
      success: 'Gelukt! Bekijk uw e-mail om het e-mailadres te bevestigen',
      error: 'Dit e-mail adres is al in gebruik',
      passwordFormatError:
        'Wachtwoord moet een Hoofdletter, kleine letter en een nummer bevatten',
      passwordShortError: 'Wachtwoord is te kort (minimaal 7 karakters)',
      passwordsDontMatchError: 'Wachtwoorden zijn niet gelijk'
    },
    forgotPasswordForm: {
      title: 'Wachtwoord vergeten',
      error: 'Er ging iets mis!',
      sendMail: 'Verstuur e-mail',
      success: 'E-mail met reset code verstuurd'
    },
    changeAccount: {
      error: 'Er ging iets mis',
      success: 'Account gewijzigd!',
      save: 'Opslaan',
      title: 'Wijzig account',
      subtitle: 'Vul de kolommen in voor het SEPA bestand',
      phoneNumber: 'Telefoonnummer',
      city: 'Stad',
      houseNumber: 'Huisnummer',
      deleteAccount: 'Verwijder account'
    },
    changeAutomatedLogout: {
      saved: 'Instellingen opgeslagen',
      error: 'Er ging iets mis'
    }
  },
  fields: {
    email: 'E-mail',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    lastNamePrefix: 'Tussenv.',
    company: 'Bedrijfsnaam',
    password: 'Wachtwoord',
    oldPassword: 'Oud wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    repeatPassword: 'Herhaal wachtwoord',
    date: 'Datum',
    actions: 'Acties',
    selectEmpty: 'Kies een optie',
    address: 'Adres',
    postalCode: 'Postcode',
    country: 'Land',
    sepa: {
      credit: 'Excasso',
      debit: 'Incasso',
      method: 'Methode',
      methodChoice: {
        normal: 'Normale incasso',
        business: 'Zakelijke incasso'
      },
      frequency: 'Frequentie',
      frequencyChoice: {
        once: 'Eenmalige incasso',
        continuous: 'Doorlopende incasso'
      },
      paymentType: 'Doel',
      paymentTypeChoice: {
        salary: 'Salarisbetaling',
        nonSalary: 'Geen salarisbetaling'
      },
      collectionDate: 'Uitvoerdatum',
      companyName: 'Bedrijfsnaam',
      iban: 'IBAN',
      bic: 'BIC',
      creditorId: 'Incassant ID',
      batch: 'Verzamelverwerking',
      batchChoice: {
        asBatch: 'Verwerk als verzamelbetaling',
        nonBatch: 'Verwerk individueel'
      },
      payments: 'Betalingen',
      invalidTransactions: 'ongeldige transacties',
      paymentInfo: {
        mandateId: 'Mandaat ID',
        mandateDate: 'Mandaat datum',
        amount: 'Bedrag',
        name: 'Naam',
        description: 'Beschrijving',
        remove: 'Verwijder'
      },
      endToEndId: 'End-to-end-ID'
    }
  },
  errors: {
    importFailed:
      'De import ging fout op regel #MESSAGE#, gebruik de excel template en probeer het nog eens.'
  }
};

export default nl;
