import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  reset
} from 'redux-form';
import DynamicForm from '../utils/DynamicForm';
import text from '../../language/text';
import {
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { renderTextField } from '../utils/materialUI';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatMoney } from '../../utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SepaValidator from './SepaValidator';
import { clearSepa, downloadExcel } from '../../reducers/sepaActions';
import { checkBIC, checkIBAN } from '../../utils/ibanChecker';
import { getBIC } from '../../utils/BICFromIBAN';
import moment from 'moment';
import SepaFormDebitButtons from './SepaFormDebitButtons';

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  transactions: any[];
  total: number;
  currentBic: string;
  handleBack: () => void;
}

const validateIban = (value: string) => {
  return checkIBAN(value) === 1 ? undefined : 'Invalid IBAN';
};

const validateBic = (value: string) => {
  return checkBIC(value) ? undefined : 'Invalid BIC';
};

const renderDebitFields = connect(() => {
  return {};
})((props: any) => {
  const { fields, dispatch } = props;
  return (
    <Grid container spacing={4}>
      {fields.map((transaction: any, index: number) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={4}>
            {index !== 0 ? (
              <Grid
                item
                xs={12}
                className="m-0 p-0"
                style={{ textAlign: 'right', marginBottom: -10 }}>
                <Button onClick={() => fields.remove(index)}>
                  <FontAwesomeIcon
                    icon={['fas', 'times']}
                    style={{ opacity: 0.8 }}
                  />
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <Typography className="my-3 text-black-50 d-block">
                    {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Field
                    name={`${transaction}.name`}
                    type="text"
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.name}
                    required
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.iban`}
                    component={renderTextField}
                    label={text.fields.sepa.iban}
                    required
                    variant="outlined"
                    fullWidth
                    validate={[validateIban]}
                    onChange={(event: any) => {
                      if (
                        checkIBAN(event.target.value) === 1 &&
                        (!fields.get(index).bic ||
                          fields.get(index).bic.length === 0)
                      ) {
                        dispatch(
                          change(
                            'SepaForm',
                            `transactions[${index}].bic`,
                            getBIC(event.target.value)
                          )
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={`${transaction}.bic`}
                    component={renderTextField}
                    label={text.fields.sepa.bic}
                    variant="outlined"
                    fullWidth
                    validate={[validateBic]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field
                    name={`${transaction}.value`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.amount}
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                    normalize={(value: number) => {
                      return value < 0 ? 0 : value;
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.endToEndId`}
                    component={renderTextField}
                    label={text.fields.sepa.endToEndId}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.remittance`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.description}
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {fields.length > 1 && index + 1 < fields.length ? (
              <Divider className={'mt-2'} />
            ) : null}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button variant="contained" onClick={() => fields.push({})}>
          {text.forms.sepaForm.addTransaction}
        </Button>
      </Grid>
    </Grid>
  );
});

const renderTransactions = connect(() => {
  return {};
})((props: any) => {
  const { fields, dispatch } = props;
  return (
    <Grid container spacing={4}>
      {fields.map((transaction: any, index: number) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={4}>
            {index !== 0 ? (
              <Grid
                item
                xs={12}
                className="m-0 p-0"
                style={{ textAlign: 'right', marginBottom: -10 }}>
                <Button onClick={() => fields.remove(index)}>
                  <FontAwesomeIcon
                    icon={['fas', 'times']}
                    style={{ opacity: 0.8 }}
                  />
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <Typography className="my-3 text-black-50 d-block">
                    {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Field
                    name={`${transaction}.name`}
                    type="text"
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.name}
                    required
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.iban`}
                    component={renderTextField}
                    label={text.fields.sepa.iban}
                    required
                    variant="outlined"
                    fullWidth
                    validate={validateIban}
                    onChange={(event: any) => {
                      if (
                        checkIBAN(event.target.value) === 1 &&
                        (!fields.get(index).bic ||
                          fields.get(index).bic.length === 0)
                      ) {
                        dispatch(
                          change(
                            'SepaForm',
                            `transactions[${index}].bic`,
                            getBIC(event.target.value)
                          )
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={`${transaction}.bic`}
                    component={renderTextField}
                    label={text.fields.sepa.bic}
                    variant="outlined"
                    fullWidth
                    validate={validateBic}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.mandateReference`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.mandateId}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.mandateReferenceDate`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.mandateDate}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field
                    name={`${transaction}.value`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.amount}
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                    normalize={(value: number) => {
                      return value < 0 ? 0 : value;
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Field
                    name={`${transaction}.endToEndId`}
                    component={renderTextField}
                    label={text.fields.sepa.endToEndId}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${transaction}.remittance`}
                    component={renderTextField}
                    label={text.fields.sepa.paymentInfo.description}
                    variant="outlined"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {fields.length > 1 && index + 1 < fields.length ? (
              <Divider className={'mt-2'} />
            ) : null}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button variant="contained" onClick={() => fields.push({})}>
          {text.forms.sepaForm.addTransaction}
        </Button>
      </Grid>
    </Grid>
  );
});

function SepaForm(props: any) {
  console.log('PROPPOS', props);

  const [isDebit, setIsDebit] = useState(false);
  const [invalidTransactions, setInvalidTransactions] = useState(0);

  useEffect(() => {
    setIsDebit(props.debit);
  }, [props.debit]);

  const handleIsDebitChange = (value: boolean) => {
    setIsDebit(value);
    props.dispatch(change('SepaForm', 'debit', value));
  };

  useEffect(() => {
    var invalid = 0;
    for (let i = 0; i < props.transactions.length; i++) {
      const t = props.transactions[i];
      if (!t.iban && !t.bic) {
        continue;
      }
      if (!t.iban || checkIBAN(t.iban) !== 1) {
        invalid++;
      } else if (!t.bic || !checkBIC(t.bic)) {
        invalid++;
      }
    }
    setInvalidTransactions(invalid);
  }, [props.transactions, setInvalidTransactions]);

  let fields: any[] = [
    {
      name: 'collectionDate',
      label: text.fields.sepa.collectionDate,
      type: 'date',
      required: true
    },
    {
      name: 'company',
      label: text.fields.sepa.companyName,
      required: true
    },
    {
      name: 'iban',
      required: true,
      label: text.fields.sepa.iban,
      onChange: (event: any) => {
        if (
          checkIBAN(event.target.value) === 1 &&
          (!props.currentBic || props.currentBic.length === 0)
        ) {
          props.dispatch(change('SepaForm', 'bic', getBIC(event.target.value)));
        }
      }
    },
    {
      name: 'bic',
      required: true,
      label: text.fields.sepa.bic
    },
    {
      name: 'creditorId',
      label: text.fields.sepa.creditorId
    },
    {
      name: 'batch',
      required: true,
      label: text.fields.sepa.batch,
      type: 'select',
      choices: [
        {
          label: text.fields.sepa.batchChoice.asBatch,
          value: 'as_batch'
        },
        {
          label: text.fields.sepa.batchChoice.nonBatch,
          value: 'non_batch'
        }
      ]
    }
  ];

  const debitFields: any[] = [
    {
      name: 'method',
      label: text.fields.sepa.method,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.methodChoice.normal,
          value: 'CORE'
        },
        {
          label: text.fields.sepa.methodChoice.business,
          value: 'B2B'
        }
      ]
    },
    {
      name: 'frequency',
      label: text.fields.sepa.frequency,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.frequencyChoice.once,
          value: 'OOFF'
        },
        {
          label: text.fields.sepa.frequencyChoice.continuous,
          value: 'RCUR'
        }
      ]
    }
  ];

  const creditFields: any[] = [
    {
      name: 'paymentType',
      label: text.fields.sepa.paymentType,
      type: 'select',
      required: true,
      choices: [
        {
          label: text.fields.sepa.paymentTypeChoice.nonSalary,
          value: 'OTHR'
        },
        {
          label: text.fields.sepa.paymentTypeChoice.salary,
          value: 'SALA'
        }
      ]
    }
  ];

  if (isDebit) {
    fields = debitFields.concat(fields);
  } else {
    fields = creditFields.concat(fields);
  }

  const handleClear = () => {
    props.dispatch(reset('SepaForm'));
    props.dispatch(clearSepa());
  };

  const handleDownloadExcel = () => {
    const mappedTransactions = props.transactions.map((transaction: any) => {
      return {
        bankAccount: {
          iban: transaction.iban,
          bic: transaction.bic,
          name: transaction.name
        },
        ...transaction,
        mandateReferenceDate:
          transaction.mandateReferenceDate == 'Invalid date'
            ? null
            : transaction.mandateReferenceDate
      };
    });
    props.dispatch(downloadExcel(mappedTransactions));
  };

  return (
    <Fragment>
      <Typography color="error" className={'font-weight-bold'}>
        {props.errorMessage}
      </Typography>
      <form onSubmit={props.handleSubmit}>
        <Grid item xs={12} className="pl-0 mt-2">
          <SepaFormDebitButtons
            isDebit={isDebit}
            handleIsDebitChange={handleIsDebitChange}
          />
        </Grid>
        <DynamicForm
          handleSubmit={props.handleSubmit}
          fields={fields}
          twoColumns
          outlined
          classes={{
            textField: 'my-2'
          }}
        />

        <ExpansionPanel style={{ marginTop: 10 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="font-weight-bold">
                  {text.fields.sepa.payments}
                </Typography>
                {invalidTransactions > 0 && (
                  <Typography color={'error'}>
                    {invalidTransactions} {text.fields.sepa.invalidTransactions}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <b>{text.forms.sepaForm.totalTransactions}: </b>
                  {props.transactions.length}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <b>{text.forms.sepaForm.total}: </b>
                  {!isNaN(props.total)
                    ? formatMoney(props.total)
                    : formatMoney(0)}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FieldArray
              name="transactions"
              component={isDebit ? renderTransactions : renderDebitFields}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div
          className="mt-3"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              className="btn"
              variant="contained"
              onClick={props.handleBack}>
              {text.basic.back}
            </Button>
            <Button
              className="btn text-danger mx-3"
              variant="contained"
              onClick={handleClear}>
              {text.pages.dashboard.components.clearForm}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleDownloadExcel}
              className="btn mx-3">
              {text.pages.dashboard.components.downloadExcel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="btn">
              {text.pages.dashboard.components.makeSepaButton}
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

const selector = formValueSelector('SepaForm');

function mapStateToProps(state: any) {
  const { sepa } = state;
  const { error } = sepa || {
    error: null
  };

  let transactions: any[] = selector(state, 'transactions');

  if (!transactions) transactions = [];
  const total = transactions.reduce((total: number, transaction: any) => {
    let number = 0;
    if (!isNaN(transaction.value)) {
      number = Number(transaction.value);
    }
    return total + number;
  }, 0);

  return {
    transactions,
    currentBic: selector(state, 'bic'),
    total,
    isFetching: false,
    errorMessage: error,
    initialValues: {
      collectionDate: state.sepa.values.collectionDate
        ? state.sepa.values.collectionDate
        : moment().format('YYYY-MM-DD'),
      transactions: state.sepa.transactions,
      ...state.sepa.values
    },
    debit: sepa.values.debit
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IProps>({
    form: 'SepaForm',
    validate: SepaValidator,
    enableReinitialize: true
  })(SepaForm)
);
